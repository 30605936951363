import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class AesService {
	public async generateKey() {
		return window.crypto.subtle.generateKey(
			{
				name: 'AES-CBC',
				length: 128,
			},
			true,
			['encrypt', 'decrypt'],
		)
	}

	public async getKey() {
		return window.crypto.subtle.importKey(
			'raw',
			new TextEncoder().encode(environment.aesKey),
			{ name: 'AES-CBC' },
			true,
			['encrypt', 'decrypt'],
		)
	}

	public getMessageEncoding(message: string) {
		return new TextEncoder().encode(message)
	}

	public getMessadeDecoding(message: ArrayBuffer) {
		return new TextDecoder().decode(message)
	}

	public async encrypt(text: string): Promise<ArrayBuffer> {
		function concatTypedArrays(a: Uint8Array, b: Uint8Array) {
			// a, b TypedArray of same type
			var c = new Uint8Array(a.length + b.length)
			c.set(a, 0)
			c.set(b, a.length)
			return c.buffer
		}

		const encoded = this.getMessageEncoding(text)
		const iv = window.crypto.getRandomValues(new Uint8Array(16))
		const key = await this.getKey()

		const encryptedData = await window.crypto.subtle.encrypt(
			{
				name: 'AES-CBC',
				iv: iv,
			},
			key,
			encoded,
		)

		// Concatenate the counter and the message

		return concatTypedArrays(iv, new Uint8Array(encryptedData))
	}

	public async decrypt(encrypted: ArrayBuffer): Promise<string> {
		const iv = new Uint8Array(encrypted.slice(0, 16))

		const data = encrypted.slice(16)
		const key = await this.getKey()

		var response = window.crypto.subtle.decrypt(
			{
				name: 'AES-CBC',
				iv: iv,
			},
			key,
			data,
		)

		return this.getMessadeDecoding(await response)
	}
}
